import React from 'react';
import { Helmet } from 'react-helmet';
import styled, { css } from 'styled-components';
import { graphql, Link } from 'gatsby';

// Component Imports
import SEO from '../components/seo';
import Container from '../components/container';
import { Section } from '../components/page-elements';
import CenteredHeaderBlock from '../components/centeredHeaderBlock';
import { D2, D3, P1 } from '../theme/typography';
import Spacer from '../components/spacer';
import BottomCut from '../components/bottomCut';

// Asset Imports
import Stripes05 from '../images/stripes/stripes05.inline.svg';

//Styled Components
const PrivacyPolicyContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 700px;
  margin: 0 auto;
`;

const PolicyHeading = styled(D3)`
  color: var(--blue1);
  position: relative;
  margin-bottom: 16px;
  text-transform: uppercase;
  span {
    color: var(--accent);
    margin-right: 16px;

    @media (min-width: 800px) {
      position: absolute;
      left: -40px;
      margin-right: 10;
    }
  }
`;

const PolicyText = styled(P1)`
  color: var(--blue1);
  margin-bottom: 32px;
  ul > li {
    list-style-type: disc;
    list-style-position: inside;
    ::marker {
      color: var(--accent);
    }
  }
  strong {
    color: var(--blue2);
    font-family: Poppins;
    font-weight: 900;
  }

  br {
    display: block;
    content: '';
  }

  a {
    text-decoration: underline var(--blue5);
    text-decoration-line: underline;
    -webkit-text-decoration-line: underline;

    &:hover {
      text-decoration-color: var(--accent);
    }
  }
`;

const TermsPage = () => {
  return (
    <>
      <SEO title='T&Cs' />
      <Helmet>
        <body className='light-nav' />
      </Helmet>
      <Section dark cuts={'bottomCut'}>
        <Container>
          <CenteredHeaderBlock
            data={{
              preHeader: 'Terms and Conditions',
              title: 'The Nitty Gritty',
              body: `How we expect visitors to act when using our website, and how we'll act in return.`,
            }}
          />
        </Container>
        <BottomCut light />
      </Section>
      <Section light cuts={'topAndBottomCut'}>
        <Container>
          <PrivacyPolicyContainer>
            <PolicyHeading>
              <span>1.</span>Acceptance of Terms
            </PolicyHeading>
            <PolicyText>
              By accessing or using www.rubberduck.ie ("the website"), you agree
              to comply with and be bound by these Terms and Conditions. If you
              do not agree with any part of these terms, please do not use our
              website.
            </PolicyText>
            <PolicyHeading>
              <span>2.</span>Use of the Website
            </PolicyHeading>
            <PolicyText>
              You agree to use this website for lawful purposes only and in a
              manner consistent with all applicable laws and regulations.
            </PolicyText>
            <PolicyHeading>
              <span>3.</span>Intellectual Property
            </PolicyHeading>
            <PolicyText>
              All content on this website, including text, graphics, logos,
              images, audio clips, and software, is the property of
              www.rubberduck.ie or its content suppliers and is protected by
              international copyright laws.
              <br />
              <Spacer size={8} />
              <em>
                Various trademarks featured on the site are held by their
                respective owners.
              </em>
            </PolicyText>
            <PolicyHeading>
              <span>4.</span>User Submissions
            </PolicyHeading>
            <PolicyText>
              By submitting content to the website, including but not limited to
              text, images, or any other material, you grant www.rubberduck.ie a
              perpetual, irrevocable, worldwide, royalty-free, and non-exclusive
              license to reproduce, modify, adapt, publish, translate, create
              derivative works from, distribute, perform, and display such
              content.
            </PolicyText>
            <PolicyHeading>
              <span>5.</span>Limitation of Liability
            </PolicyHeading>
            <PolicyText>
              www.rubberduck.ie and its affiliates, officers, employees, agents,
              partners, and licensors shall not be liable for any direct,
              indirect, incidental, special, or consequential damages resulting
              from the use or inability to use the website.
            </PolicyText>
            <PolicyHeading>
              <span>6.</span>Third-Party Links
            </PolicyHeading>
            <PolicyText>
              The website may contain links to third-party websites.
              www.rubberduck.ie is not responsible for the content or privacy
              practices of these linked sites.
            </PolicyText>
            <PolicyHeading>
              <span>7.</span>Privacy
            </PolicyHeading>
            <PolicyText>
              Use of this website is also governed by our{' '}
              <a href='/privacy-policy'>Privacy Policy</a>, which is
              incorporated into these Terms and Conditions by reference.
            </PolicyText>
            <PolicyHeading>
              <span>8.</span>Governing Law
            </PolicyHeading>
            <PolicyText>
              These Terms and Conditions are governed by and construed in
              accordance with the laws of Ireland. Any disputes arising from or
              in connection with these terms shall be subject to the exclusive
              jurisdiction of the Irish courts.
            </PolicyText>
            <PolicyHeading>
              <span>9.</span>Contact Us
            </PolicyHeading>
            <PolicyText>
              If you have any questions or concerns regarding this Privacy
              Policy, please contact us at:{' '}
              <a href='mailto:info@rubberduck.ie'>info@rubberduck.ie</a>
            </PolicyText>
            <PolicyHeading>
              <span>10.</span>Changes to Terms and Conditions
            </PolicyHeading>
            <PolicyText>
              www.rubberduck.ie reserves the right to modify, suspend, or
              terminate these Terms and Conditions at any time without prior
              notice.
            </PolicyText>
          </PrivacyPolicyContainer>
        </Container>
        <BottomCut dark />
      </Section>
    </>
  );
};

export default TermsPage;
